import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_SMALL } from '@/constant/ui'

export const Wrapper = styled.div<{ background: string }>`
  position: relative;
  overflow: hidden;
  background: ${({ background }) => background};
`
export const LargeTileSwiperCont = styled.div`
  width: 100%;
  position: relative;

  .swiper {
    padding-left: 16px;
    padding-right: 16px;
  }
  .large-swiper-tile {
    width: 20vw;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    .large-swiper-tile {
      width: 34vw;
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100vw;
    overflow: hidden;
    left: 0;

    .large-swiper-tile {
      width: 80vw;
    }

    .swiper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`
export const TileImage = styled.div`
  width: 100%;
  height: 310px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.45s var(--cubicBezierImage);
    transform: scale(1);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 220px;
  }
`
export const Tile = styled(Link)`
  color: var(--text-primary);
  text-decoration: none;
  display: block;

  &:hover {
    ${TileImage} img {
      transform: scale(1.1);
    }
  }
`
