import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_LARGE, BREAKPOINT_SMALL } from '@/constant/ui'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: var(--brand-secondary);
`
export const CustomLinksSwiperCont = styled.div`
  width: 100%;
  position: relative;

  .swiper {
    padding-left: calc((100% - 1296px) / 2);
    padding-right: calc((100% - 1296px) / 2);
  }
  .featured-swiper-tile {
    width: 20vw;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    .swiper {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100vw;
    overflow: hidden;
    left: 0;

    .featured-swiper-tile {
      width: 55vw;
      box-sizing: border-box;
    }
    .swiper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`
export const Subheading = styled.div`
  letter-spacing: 1.5px;
`

export const TileImage = styled.div`
  width: 100%;
  height: 260px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1);
    transition: 0.45s var(--cubicBezierImage);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 220px;
  }
`

export const Tile = styled(Link)`
  color: var(--text-primary);
  text-decoration: none;
  display: block;

  &:hover {
    ${TileImage} img {
      transform: scale(1.1);
    }
  }
`
