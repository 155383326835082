import Container from './Container'
import { Spacing, FlexibleHeading as Heading, Body } from '@big-red-group/storefront-common-ui'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { BasicSwiperTile } from '@/types'
import { CustomLinksSwiperCont, Tile, TileImage, Wrapper } from './CustomLinksSwiper.styles'
import { SwiperButtons, SwiperHeadingContainer, SwiperLeft, SwiperRight } from './Swiper.styles'
import 'swiper/css'
import 'swiper/css/navigation'
import { generateHtmlSafeId } from '@/helpers/UtilHelper'
import { useRouter } from 'next/router'
import { useAnalyticsMetaData } from '@/hooks/AnalyticsMetaDataHook'

const CustomLinksSwiper: React.FC<{
  tiles: BasicSwiperTile[]
  variant: string
  sectionTitle?: string
  urlPrefix?: string
  name?: string
}> = ({ tiles, variant, sectionTitle, urlPrefix = '', name }) => {
  const swiperId = generateHtmlSafeId(variant)
  const router = useRouter()
  const lang = router.query.lang
  const metadata = useAnalyticsMetaData()

  const handleCategoryClick = () => {
    if (name === 'contextual-category-carousel') {
      metadata.setIsPopularCategory(true)
    }
  }
  return (
    <Wrapper>
      <Container spacing="M">
        <SwiperHeadingContainer centeredArrows={sectionTitle == undefined} data-testid="clink-swiper-header">
          {sectionTitle && (
            <Heading size="L" dataTestId="clink-swiper-title">
              {sectionTitle}
            </Heading>
          )}
          <SwiperButtons>
            <SwiperLeft className={`${swiperId}-prev`}></SwiperLeft>
            <SwiperRight className={`${swiperId}-next`}></SwiperRight>
          </SwiperButtons>
        </SwiperHeadingContainer>
      </Container>
      <CustomLinksSwiperCont>
        <Swiper
          modules={[Navigation]}
          navigation={{ prevEl: `.${swiperId}-prev`, nextEl: `.${swiperId}-next` }}
          spaceBetween={16}
          slidesPerView="auto"
          lazyPreloadPrevNext={1}
        >
          {tiles.map(({ image, name, urlSegment }) => (
            <SwiperSlide className="clink-swiper-tile" key={urlSegment}>
              <Tile href={`/${urlPrefix}${urlSegment}`} onClick={handleCategoryClick}>
                <TileImage>
                  <Image src={image} alt={name} width={0} height={0} sizes="100%" loading="lazy" />
                </TileImage>
                <Spacing size="S" />
                <Body size="L" weight="medium">
                  {name}
                </Body>
              </Tile>
            </SwiperSlide>
          ))}
        </Swiper>
      </CustomLinksSwiperCont>
    </Wrapper>
  )
}

export default CustomLinksSwiper
