import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_MEDIUM_LARGE } from '@/constant/ui'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1 / 0.7;
  gap: 15px;
  width: 130%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  margin: 60px 0;

  @media (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
    width: 110%;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 100%;
    gap: 10px;
    aspect-ratio: 1 / 0.9;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
  }
`

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  height: 50%;
  gap: 15px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    gap: 10px;
  }
`

export const BottomRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  height: 50%;
  gap: 15px;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    gap: 10px;
  }
`

export const Image = styled.div<{ image: string }>`
  background-image: url('${({ image }) => image}');
  background-position: center;
  max-width: 100%;
  width: 100%;
  background-size: cover;
  height: auto;
  box-sizing: border-box;
`
