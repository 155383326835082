import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_MEDIUM_LARGE, BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'

export const Images = styled.div`
  position: relative;
  text-align: center;
  margin: 200px 0;
  pointer-events: none;

  img {
    width: 420px;
    height: 420px;
    object-fit: cover;
  }

  img:first-child {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-25%, -25%);
    z-index: ${Z_INDEX.low};
  }

  img:nth-child(2) {
    position: relative;
    z-index: ${Z_INDEX.medium};
  }

  img:last-child {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(25%, 25%);
    z-index: ${Z_INDEX.medium + 1};
  }

  @media (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
    img {
      width: 380px;
      height: 380px;
    }
    img:first-child {
      transform: translate(-10%, -25%);
    }
    img:last-child {
      transform: translate(10%, 25%);
    }
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    margin: 70px 0;

    img {
      width: 300px;
      height: 300px;
    }
    img:first-child {
      transform: translate(-5%, -5%);
    }
    img:last-child {
      transform: translate(5%, 5%);
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 70px 0;

    img {
      width: 300px;
      height: 300px;
    }
    img:first-child {
      left: 50%;
      top: 50%;
      transform: translate(-55%, -55%);
    }
    img:last-child {
      left: 50%;
      top: 50%;
      transform: translate(-45%, -45%);
    }
  }
`
