import styled from 'styled-components'
import { BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'

export const ImageWrapper = styled.div`
  height: 40vh;
  width: 100%;
  position: relative;
`

const contributorImageSize: number = 110

export const ContributorImage = styled.div<{ imageUrl: string }>`
  background: url(${({ imageUrl }) => imageUrl});
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  width: ${contributorImageSize}px;
  height: ${contributorImageSize}px;
  border: 4px solid var(--bg-white);
  position: absolute;
  bottom: -${contributorImageSize / 2}px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: ${Z_INDEX.medium};
`

export const ContentWrapper = styled.div`
  padding-top: ${contributorImageSize / 2}px;
  width: 100%;
  height: auto;
  background-color: var(--bg-black);
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DescriptionWrapper = styled.div`
  max-width: 800px;
`

export const ImageGrid = styled.div`
  width: 100%;
  height: 450px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`

export const GridImage = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
  position: relative;

  &:nth-child(2) {
    grid-area: span 2 / span 1;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      background-color: var(--bg-black);
      height: 25px;
      top: 0;
    }
  }

  &:nth-child(4),
  &:nth-child(5) {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      background-color: var(--bg-black);
      height: 25px;
      bottom: 0;
    }
  }
`

export const ImageSwiperWrapper = styled.div`
  display: none;

  .swiper-tile {
    width: 80vw;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: block;
  }
`

export const ImageSlide = styled.div`
  height: 200px;
  width: 100px;
`
