import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Button from '@/components/Button'
import Container from '@/components/Container'
import TrustFactorBar from '@/components/TrustFactorBar'
import {
  HeroWrapper,
  HeroSlide,
  HeroNav,
  TitleLabel,
  LeftArrow,
  RightArrow,
  HeroHeading,
} from '@/components/HomeHero.styles'
import { HomeHeroSlide } from '@/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import Label from './Label'

const HomeHero: React.FC<{
  heroSlides: HomeHeroSlide[]
  title: string
  subtitle: string
}> = ({ heroSlides, title, subtitle }) => {
  return (
    <HeroWrapper>
      {heroSlides.length > 1 && (
        <HeroNav color="text-inverse">
          <LeftArrow className="home-hero-prev"></LeftArrow>
          <RightArrow className="home-hero-next"></RightArrow>
        </HeroNav>
      )}
      <HeroHeading>
        <Container>
          <h1>
            <Label as="span" color="text-inverse">
              {subtitle}
            </Label>
          </h1>
        </Container>
      </HeroHeading>
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true }}
        navigation={{ prevEl: `.home-hero-prev`, nextEl: `.home-hero-next` }}
        breakpoints={{
          0: {
            spaceBetween: 16,
            slidesPerView: 'auto',
            loop: true,
            lazyPreloadPrevNext: 1,
          },
        }}
      >
        {heroSlides.map(({ cta, image, link, heading }, index) => (
          <SwiperSlide className="large-swiper-tile" key={index}>
            <HeroSlide background={image}>
              <Container>
                <Heading weight="fancy" size="XL" as="h2" alignment='center' background="brand-primary">
                  <span>{heading}</span>
                </Heading>
                <Spacing size="S" />
                <Button size="M" onClick={() => (window.location.href = link)}>
                  {cta}
                </Button>
              </Container>
            </HeroSlide>
          </SwiperSlide>
        ))}
      </Swiper>
      <TrustFactorBar />
    </HeroWrapper>
  )
}

export default HomeHero
