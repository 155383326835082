import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_MEDIUM_LARGE } from '@/constant/ui'

export const ImageContainer = styled.div`
  width: 140%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 1 / 0.5;
  margin: 50px 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
    width: 110%;
  }
  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    aspect-ratio: 1 / 0.65;
    width: 100%;
    margin: 30px 0;
  }
`
