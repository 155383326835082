import styled from 'styled-components'
import Link from 'next/link'
import { BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'
import { BrandColor } from '@big-red-group/storefront-common-ui'

export const Wrapper = styled.div`
  span,
  h1 {
    text-align: center;
    display: block;

    @media (max-width: ${BREAKPOINT_SMALL}px) {
      text-align: left;
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    span {
      color: var(--text-inverse);
    }
    h1 {
      color: var(--brand-primary);
    }
  }
`

export const ImageForegroundWrapper = styled(Wrapper)<{ hasImage: boolean }>`
  position: relative;

  ${({ hasImage }) =>
    hasImage &&
    `
      &:before {
        content: '';
        position: absolute;
        background: white;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25vh;
        display: block;
        z-index: ${Z_INDEX.base};
      }
  `}

  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    > div {
      display: flex;
      flex-direction: column-reverse;
    }

    img {
      height: 30vh;
    }

    &:before {
      display: none;
    }
  }
`

export const BlogHeaderImage = styled.div`
  height: 45vh;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 30vh;
  }
`

export const BlogHeroForegroundText = styled.div`
  margin-top: -100px;
  background: white;

  > div {
    padding: 70px 10%;
    box-sizing: border-box;
    text-align: center;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 0;
    background: var(--brand-secondary);

    > div {
      padding: 48px 25px;
    }
  }
`

export const HeroLink = styled(Link)<{ color: BrandColor }>`
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: ${({ color }) => `1px solid var(--${color})`};
  border-radius: 50%;
  color: ${({ color }) => `var(--${color})`};

  &:hover {
    opacity: 0.7;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0;
    color: var(--brand-primary);
    border-color: var(--brand-primary);
  }
`
