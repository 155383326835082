import { Images } from '@/components/widgets/ImageFan.styles'
import Image from 'next/image'

type ImageFanProps = {
  first: string
  second: string
  third: string
}

const ImageFan: React.FC<ImageFanProps> = ({ first, second, third }) => {
  return (
    <Images>
      <Image src={first} alt="first-img" width={0} height={0} sizes="100%" />
      <Image src={second} alt="second-img" width={0} height={0} sizes="100%" />
      <Image src={third} alt="third-img" width={0} height={0} sizes="100%" />
    </Images>
  )
}

export default ImageFan
