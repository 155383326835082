import Image from 'next/image'
import BackgroundImage from './BackgroundImage'
import Container from './Container'
import BlogHeader from './BlogHeader'
import { Spacing } from '@big-red-group/storefront-common-ui'
import Icon from './Icons'
import {
  Wrapper,
  ImageForegroundWrapper,
  BlogHeaderImage,
  BlogHeroForegroundText,
  HeroLink,
} from '@/components/BlogHero.styles'
import { BlogHero as BlogHeroProps } from '@/types'

const BlogHero: React.FC<BlogHeroProps> = ({ title, image, type = 'imageForeground', label, link }) => {
  const color = type === 'imageForeground' ? 'brand-primary' : 'brand-secondary'

  const renderHeroContent = () => {
    return (
      <BlogHeader title={title} label={label} inverse={type === 'imageForeground'}>
        {link && (
          <>
            <Spacing size="XL" />
            <HeroLink href={link} color={color} data-testid="hero-link">
              <Icon name="link" width={24} height={24} />
            </HeroLink>
          </>
        )}
      </BlogHeader>
    )
  }

  if (type === 'imageBackground' && image) {
    return (
      <Wrapper data-testid="image-bg">
        <BackgroundImage imageUrl={image}>
          <BlogHeaderImage />
        </BackgroundImage>
        <Container fullWidthMobile>
          <BlogHeroForegroundText>{renderHeroContent()}</BlogHeroForegroundText>
        </Container>
      </Wrapper>
    )
  } else {
    return (
      <ImageForegroundWrapper data-testid="image-fg" hasImage={image ? true : false}>
        <BackgroundImage>
          <Container>
            <Spacing size="XXL" />
            {renderHeroContent()}
            <Spacing size="XXL" />
          </Container>
          {image && (
            <Container fullWidthMobile>
              <Image src={image} alt={title} width={0} height={0} sizes="100%" />
            </Container>
          )}
        </BackgroundImage>
      </ImageForegroundWrapper>
    )
  }
}

export default BlogHero
