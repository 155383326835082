import styled from 'styled-components'
import { SwiperLeft, SwiperRight } from './Swiper.styles'
import { BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'
import { Wrapper as Container } from './Container.styles'

export const HeroWrapper = styled.div`
  position: relative;
  border-top: 1px solid #8d8d8d;

  .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid var(--text-inverse);
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: var(--brand-primary);
    border-color: var(--brand-primary);
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 20px;
  }
  @media (max-width: 410px) {
    h2 {
      font-size: 28px;
    }
  }
`
export const LeftArrow = styled(SwiperLeft)`
  pointer-events: all;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.4);

  &:before {
    width: 15px;
    height: 15px;
    left: calc(50% + 3px);
    border-style: solid;
    border-width: 3px 0 0 3px;
    border-color: var(--text-inverse);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 45px;
    height: 45px;
    background: rgba(0, 0, 0, 0.05);

    &:before {
      width: 8px;
      height: 8px;
    }
  }
`
export const RightArrow = styled(SwiperRight)`
  pointer-events: all;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.4);

  &:before {
    width: 15px;
    height: 15px;
    left: calc(50% - 3px);
    border-style: solid;
    border-width: 3px 3px 0 0;
    border-color: var(--text-inverse);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 45px;
    height: 45px;
    background: rgba(0, 0, 0, 0.05);

    &:before {
      width: 8px;
      height: 8px;
    }
  }
`
export const HeroNav = styled.div`
  position: absolute;
  top: calc(50% - 30px);
  transform: translateY(-50%);
  left: 25px;
  width: calc(100% - 50px);
  z-index: ${Z_INDEX.medium};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  .swiper-button-disabled::before {
    border-color: #cccccc;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    /* top: auto;
    bottom: calc(90px + 25px);
    transform: none; */
    top: 50%;
    width: calc(100% - 30px);
    left: 15px;
  }
`
export const HeroSlide = styled.div<{ background: string }>`
  height: 450px;
  background-image: url(${({ background }) => background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 960px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    box-sizing: border-box;
    height: 280px;

    button {
      padding: 10px 12px;
    }
  }
`
export const TitleLabel = styled.div`
  h1,
  span {
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.8);
  }
`

export const HeroHeading = styled.div`
  background: var(--brand-secondary);
  width: 100%;
  padding: 15px 0 10px 0;
  text-align: center;

  > ${Container} {
    gap: 30px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 10px 0;

    h1 span {
      font-size: 14px;
    }
  }
`
