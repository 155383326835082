import Container from './Container'
import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { BasicSwiperTile } from '@/types'
import { LargeTileSwiperCont, Tile, TileImage, Wrapper } from './LargeTileSwiper.styles'
import { SwiperButtons, SwiperHeadingContainer, SwiperLeft, SwiperRight } from './Swiper.styles'
import 'swiper/css'
import 'swiper/css/navigation'
import { generateHtmlSafeId } from '@/helpers/UtilHelper'

const LargeTileSwiper: React.FC<{
  tiles: BasicSwiperTile[]
  variant: string
  sectionTitle?: string
  inverse?: boolean
  background?: string
  urlPrefix?: string
}> = ({ tiles, variant, sectionTitle, inverse = false, background = 'transparent', urlPrefix = '' }) => {
  const textColor = inverse ? 'text-inverse' : 'brand-secondary'
  const swiperId = generateHtmlSafeId(variant)

  return (
    <Wrapper background={background} data-testid="large-tile-swiper-wrap">
      <Container spacing="M">
        <SwiperHeadingContainer centeredArrows={sectionTitle == undefined} data-testid="large-tile-swiper-header">
          {sectionTitle && (
            <Heading size="L" color={textColor} weight="heading" dataTestId="large-tile-swiper-title">
              {sectionTitle}
            </Heading>
          )}
          <SwiperButtons color={textColor}>
            <SwiperLeft className={`${swiperId}-prev`} data-testid="large-tile-swiper-left"></SwiperLeft>
            <SwiperRight className={`${swiperId}-next`} data-testid="large-tile-swiper-right"></SwiperRight>
          </SwiperButtons>
        </SwiperHeadingContainer>
      </Container>
      <LargeTileSwiperCont>
        <Swiper
          modules={[Navigation]}
          navigation={{ prevEl: `.${swiperId}-prev`, nextEl: `.${swiperId}-next` }}
          breakpoints={{
            0: {
              spaceBetween: 16,
              slidesPerView: 'auto',
              loop: false,
              lazyPreloadPrevNext: 1,
            },
            720: {
              spaceBetween: 16,
              slidesPerView: 'auto',
              loop: true,
              lazyPreloadPrevNext: 1,
            },
          }}
        >
          {tiles.map(({ image, name, urlSegment }) => (
            <SwiperSlide className="large-swiper-tile" key={urlSegment}>
              <Tile href={`/en/${urlPrefix}${urlSegment}`}>
                <TileImage>
                  <Image src={image} alt={name} width={0} height={0} sizes="100%" loading="lazy" />
                </TileImage>
                <Spacing size="M" />
                <Heading size="M" as="h3" weight="heading" color={textColor} dataTestId="large-tile-swiper-name">
                  {name}
                </Heading>
              </Tile>
            </SwiperSlide>
          ))}
        </Swiper>
      </LargeTileSwiperCont>
    </Wrapper>
  )
}

export default LargeTileSwiper
