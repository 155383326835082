import React, { useMemo } from 'react'
import Head from 'next/head'
import { Breadcrumb, EntityDetails, TemplateDefinition, TemplateWidget, WidgetRegionLink } from '@/types'
import { createCanonicalLink, toName } from '@/helpers/UrlHelper'
import { useRouter } from 'next/router'
import {
  getFilterDataRegionWidget,
  getFiltersListingPreview,
  getNextWidgetName,
  getWidget,
  plpProductToWidgetProduct,
} from '@/helpers/WidgetHelper'
import { DefaultPropertiesContext } from '@/context/DefaultPropertiesContext'
import { useContext } from 'react'
import { AgentConfigurationResponse } from '@big-red-group/storefront-common-checkout'
import { getFilteredCategories, getRegionFiltersFromProducts } from '@/helpers/FilterHelper'
import MobileSearchTrigger from './MobileSearchTrigger'
import { createPageTitle } from '@/helpers/PageTitleHelper'

const PlpPageTemplate: React.FC<{
  entityType?: 'REGION' | 'CAMPAIGN' | 'CATEGORY' | 'SUBCATEGORY' | 'STATE' | 'THINGS_TO_DO' | 'HOME'
  agentConfiguration: AgentConfigurationResponse
  entityDetails: EntityDetails
  templateDefinition?: TemplateDefinition
  canonicalLink: string
}> = ({ entityType, agentConfiguration, entityDetails, templateDefinition, canonicalLink }) => {
  const router = useRouter()
  const { lang, part1: region } = router.query
  const breadcrumbs: Breadcrumb[] = [{ text: 'Home', href: `/${lang}` }, { text: toName(region as string) }]
  const { id, title, heading, description, image, summary, products, name, urlSegment } = entityDetails //todo: add optional image to entity details(?)
  const { handlerKey } = agentConfiguration
  const defaultProperties = useContext(DefaultPropertiesContext)
  const widgets = templateDefinition?.widgets

  const contextualCategories = useMemo(
    () => getFilteredCategories(entityDetails.products, defaultProperties?.searchProperties.categories),
    [entityDetails.id]
  )

  function getProductWidget(widget: TemplateWidget): TemplateWidget {
    if (entityType === 'THINGS_TO_DO' && widget.name === 'category-product-carousel') {
      widget.products = plpProductToWidgetProduct(products)
    }
    return widget
  }

  function getContextualRegions(): WidgetRegionLink[] | undefined {
    if (entityType === 'THINGS_TO_DO') {
      const regionLinks = widgets?.find(({ name }) => name === 'region-links')
      if (!regionLinks) {
        return undefined
      }

      return getFilterDataRegionWidget(regionLinks)
    }
    if (entityType === 'STATE' && defaultProperties?.searchProperties.regions) {
      return getRegionFiltersFromProducts(products, defaultProperties?.searchProperties.regions)
    }

    return undefined
  }

  const renderPageTitle = () => {
    let defaultPageTitle = ''
    switch (entityType) {
      case 'REGION':
        defaultPageTitle = `${name}`
        break
      case 'CATEGORY':
        defaultPageTitle = `${entityDetails?.regionName ?? ''} ${name}`
        break
      case 'SUBCATEGORY':
        defaultPageTitle = `${entityDetails?.regionName ?? ''} ${name} Adventures & Vouchers`
        break
      case 'STATE':
        defaultPageTitle = `${name} Adventures & Vouchers`
        break
      case 'THINGS_TO_DO':
        defaultPageTitle = `${name}`
        break
      default:
        break
    }

    return title ? title : createPageTitle(defaultPageTitle)
  }

  return (
    <>
      <Head>
        {entityType == 'HOME' ? (
          <>
            <title>Adrenaline | 2,500+ Adventures, Gifts and Things to Do</title>
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content="Choose adventure at Adrenaline. Discover our range of over 2,500 exciting adventures across Australia, available to book now or gift instantly."
            />
            <meta name="pageid" content="home" />
            <link rel="canonical" href={createCanonicalLink('')} />
          </>
        ) : (
          <>
            <title>{renderPageTitle()}</title>
            <meta name="description" content={summary} />
            <meta name="pageid" content={urlSegment} />
            <meta name="entityType" content={entityType} />
            <meta name="entityName" content={entityDetails?.name} />
            <link rel="canonical" href={canonicalLink} />
          </>
        )}
      </Head>
      <MobileSearchTrigger />
      {templateDefinition?.widgets?.map((widget: TemplateWidget, index: number) => (
        <React.Fragment key={index}>
          {widget.name === 'product-listing' && entityType !== 'HOME'
            ? getFiltersListingPreview(
                entityType!,
                entityDetails,
                getContextualRegions(),
                contextualCategories,
                handlerKey
              )
            : getWidget(
                getProductWidget(widget),
                getNextWidgetName(templateDefinition.widgets, index),
                entityType!,
                entityDetails,
                handlerKey
              )}
        </React.Fragment>
      ))}
    </>
  )
}

export default PlpPageTemplate
