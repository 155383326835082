import { Wrapper, Image, TopRow, BottomRow } from '@/components/widgets/ImageGridFour.styles'

type ImageGridFourProps = {
  first: string
  second: string
  third: string
  fourth: string
}

const ImageGridFour: React.FC<ImageGridFourProps> = ({ first, second, third, fourth }) => {
  return (
    <Wrapper>
      <TopRow>
        <Image image={first} />
        <Image image={second} />
      </TopRow>
      <BottomRow>
        <Image image={third} />
        <Image image={fourth} />
      </BottomRow>
    </Wrapper>
  )
}

export default ImageGridFour
