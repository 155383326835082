import styled from 'styled-components'
import { BREAKPOINT_MEDIUM } from '@/constant/ui'

export const Wrapper = styled.div<{ imageUrl: string }>`
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-position: center;
  background-size: cover;
  height: 650px;
  width: 100%;
  background-color: #dedede;
  background-blend-mode: multiply;
  margin: 60px 0;
  position: relative;

  h2 {
    position: absolute;
    top: 35px;
    left: 35px;
    width: 350px;
  }

  button {
    position: absolute;
    bottom: 35px;
    left: 35px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: 300px;
    margin: 40px 0;

    h2 {
      width: fit-content;
      max-width: 40%;
      left: 20px;
      top: 20px;
    }

    button {
      bottom: 25px;
      left: 25px;
    }
  }
`
