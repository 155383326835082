import { Wrapper } from '@/components/widgets/PromoImage.styles'
import { FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Button from '@/components/Button'

type PromoImageProps = {
  image: string
  heading: string
  link: string
  cta: string
}

const PromoImage: React.FC<PromoImageProps> = ({ image, heading, link, cta }) => {
  return (
    <Wrapper imageUrl={image}>
      <Heading size="L" weight="fancy" margin>
        <span>{heading}</span>
      </Heading>
      <Button size="S" onClick={() => (window.location.href = `${link}`)}>
        {cta}
      </Button>
    </Wrapper>
  )
}

export default PromoImage
