import styled from 'styled-components'
import { ImageAlignment } from '@/types'
import { BREAKPOINT_MEDIUM } from '@/constant/ui'
import { BrandColor } from '@big-red-group/storefront-common-ui'

export const ImageBlock = styled.div<{ image: string }>`
  width: 48%;
  flex-shrink: 0;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: 360px;
    width: 100%;
  }
`
export const TextBlock = styled.div`
  width: 52%;
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    min-height: unset;
    width: 100%;
  }
`
export const Wrapper = styled.div<{ imageAlignment: ImageAlignment; inverse: boolean; customBackground?: BrandColor }>`
  ${({ inverse, customBackground }) =>
    inverse
      ? `
      background-size: cover;
      background-position: center;
      background-color: var(--brand-secondary);
    `
      : `
      background: var(--${customBackground ? customBackground : 'grey-10'});
    `}
  padding: 80px 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: ${({ imageAlignment }) => (imageAlignment === 'left' ? 'row' : 'row-reverse')};
  }
  ${TextBlock} {
    padding: ${({ imageAlignment }) => (imageAlignment === 'left' ? '8% 0 8% 6%' : '8% 6% 8% 0')};
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 40px 0;

    > div {
      flex-direction: column;
    }

    ${TextBlock} {
      padding: 30px;
    }
  }
`
