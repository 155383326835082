import styled from 'styled-components'
import { Wrapper } from '@/components/Container.styles'
import { Tab } from '@headlessui/react'
import { SwiperHeadingContainer } from '@/components/Swiper.styles'
import { BREAKPOINT_SMALL } from '@/constant/ui'

export const ProductsWrapper = styled.div`
  ${Wrapper} {
    padding: 0;
  }
  ${SwiperHeadingContainer} {
    justify-content: flex-end;
  }
`

export const TabButton = styled(Tab)`
  border: none;
  outline: none;
  background: transparent;
  padding: 10px 20px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  color: var(--text-dark);
  border-bottom: 2px solid transparent;

  &:hover {
    color: var(--text-primary-light);
  }

  &[aria-selected='true'] {
    background: var(--brand-primary);
    font-family: var(--font-medium);
    border-bottom-color: var(--brand-secondary);
    color: var(--text-dark);
  }
`

export const TabList = styled(Tab.List)`
  display: flex;
  border-bottom: 1px solid var(--grey-100);
  row-gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const HeadingWrapper = styled.div`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 16px;
  }
`
