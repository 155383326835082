import { Wrapper, Background, HeroImage, Content, TextWrapper } from '@/components/widgets/PlpHeader.styles'
import { FlexibleHeading as Heading, Spacing, Body } from '@big-red-group/storefront-common-ui'
import Container from '@/components/Container'
import { PlpHeader as PlpHeaderProps } from '@/types'
import Icon from '../Icons'

const PlpHeader: React.FC<PlpHeaderProps> = ({ title, image, text, children, spacing = true }) => {
  if (image) {
    return (
      <>
        <Wrapper>
          <Background>
            <HeroImage src={image} alt="Hero image" fetchPriority="high" />
          </Background>
          <Container>
            <Icon name="logoPrimary" />
            <Content>
              <Heading weight="fancy" size="XL" as="h1" color="brand-primary">
                {title && <div dangerouslySetInnerHTML={{ __html: title }}></div>}
              </Heading>
              {text && (
                <>
                  <Spacing size="XXS" />
                  <TextWrapper>
                    <Body size="M" weight="medium" color="text-inverse">
                      {text}
                    </Body>
                  </TextWrapper>
                </>
              )}
              {children && (
                <>
                  <Spacing size="S" />
                  {children}
                </>
              )}
            </Content>
          </Container>
        </Wrapper>
        {spacing && <Spacing size="XXL" />}
      </>
    )
  } else {
    return (
      <>
        <Wrapper noImage>
          <Container>
            <Heading weight="fancy" size="L" as="h1" alignment="center">
              {title && <div dangerouslySetInnerHTML={{ __html: title }}></div>}
            </Heading>
            {text && (
              <>
                <Spacing size="M" />
                <Body size="L" weight="regular" color="text-inverse">
                  {text}
                </Body>
              </>
            )}
          </Container>
        </Wrapper>
        {spacing && <Spacing size="XXL" />}
      </>
    )
  }
}

export default PlpHeader
