import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@/constant/ui'
import Button from '@/components/Button'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
`
export const Tiles = styled.div`
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    .prefix-slide {
      width: 55vw;
    }
  }
`
export const Wrapper = styled.div``

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`
export const HeadingButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
  }
`

export const DesktopButton = styled(Button)`
  display: block;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`

export const MobileButton = styled(Button)`
  display: none;
  width: 100%;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: block;
  }
`

export const ScrollWrapper = styled.div`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const ProfileImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 24px;
  overflow-x: scroll;
  align-items: center;
  overflow: initial;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const ProfilePrefixWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 14px;
  cursor: pointer;
`

export const PrefixImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`
