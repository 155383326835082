import styled from 'styled-components'
import { Alignment } from '@/types'

export const Wrapper = styled.div<{ align: Alignment }>`
  display: block;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-regular);
  gap: 5px;
  text-align: ${({ align }) => align};
`
