import styled from 'styled-components'
import { BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'
import { Wrapper as Container } from '@/components/Container.styles'

export const Wrapper = styled.div<{ noImage?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: 450px;
  position: relative;
  overflow: hidden;
  ${({ noImage }) =>
    noImage &&
    `
    background: var(--brand-secondary);
    height: auto;
    padding: 50px 0;
    text-align: center;

    h1 {
      color: var(--text-inverse);
      width: 100%;
    }
  `}

  > ${Container} {
    position: static;

    > svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: auto;
      z-index: ${Z_INDEX.low};
      color: var(--text-inverse);
      height: 450px;
      opacity: 0.1;
      pointer-events: none;
      display: block;
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    height: unset;

    > ${Container} {
      background: var(--brand-secondary);
      position: relative;
      overflow: hidden;

      > svg {
        height: 230px;
        position: absolute;
      }
    }
  }
`

export const ContentContainer = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 50%;
  text-wrap: balance;

  ${({ fullWidth }) =>
    fullWidth &&
    `
   width: 100%;
   
  `}

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  height: 530px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    height: 430px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: 380px;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 100%;
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-start;
    overflow: hidden;
    padding-right: 25px;
  }
`

export const FeaturedImage = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: 270px;
    width: 100%;
  }
`

export const Content = styled.div`
  width: 50%;

  > h1 {
    color: var(--text-inverse);
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
    background: var(--brand-secondary);
    padding: 35px 0;
  }
`

export const Background = styled.div`
  position: absolute;
  z-index: ${Z_INDEX.base};
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: block;
  background: var(--brand-secondary);

  &:after {
    content: '';
    display: block;
    background: var(--brand-secondary);
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    transform: skewX(-5deg);
    pointer-events: none;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 230px;
    width: 100%;
    display: block;
    position: relative;
    left: 0;
    top: 0;

    &:after {
      display: none;
    }
  }
`
export const HeroImage = styled.img`
  width: 60%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  object-fit: cover;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100%;
    height: 300px;
  }
`

export const TextWrapper = styled.div`
  position: relative;
  width: 90%;
  border-radius: 2px;
  box-sizing: border-box;
`
