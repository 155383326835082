import React from 'react'
import { Tab } from '@headlessui/react'
import { FeaturedProductTab } from '@/types'
import { Spacing, FlexibleHeading as Heading, Body } from '@big-red-group/storefront-common-ui'
import ProductCollection from '@/components/ProductCollection'
import { ProductsWrapper, TabList, TabButton, HeadingWrapper } from '@/components/widgets/FeaturedProductTabs.styles'
import { toProduct } from '@/helpers/WidgetHelper'
import Container from '@/components/Container'

const FeaturedProductTabs: React.FC<{ title: string; tabs: FeaturedProductTab[] }> = ({ title, tabs }) => {
  return (
    <>
      <Tab.Group>
        <Container fullWidthMobile>
          <HeadingWrapper>
            <Heading size="L" as="h2">
              {title}
            </Heading>
            <Spacing size="M" />
            <TabList>
              {tabs?.map((tab) => {
                return (
                  <TabButton key={tab.label}>
                    <Body size="M" weight="medium">
                      {tab.label}
                    </Body>
                  </TabButton>
                )
              })}
            </TabList>
          </HeadingWrapper>
        </Container>
        <Spacing size="S" />
        <Tab.Panels>
          {tabs?.map((tab, index) => {
            const viewMore = !!(tab.cta && tab.link)
            return (
              <Tab.Panel key={index}>
                <ProductsWrapper>
                  <ProductCollection
                    products={toProduct(tab.products)}
                    id={tab.label}
                    smallMobileTile={false}
                    viewMore={viewMore ? { cta: tab.cta!, link: tab.link! } : undefined}
                  />
                </ProductsWrapper>
              </Tab.Panel>
            )
          })}
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default FeaturedProductTabs
