import Container from './Container'
import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { FeaturedSwiperTile } from '@/types'
import { CustomLinksSwiperCont, Tile, TileImage, Wrapper, Subheading } from './FeaturedLinksSwiper.styles'
import { SwiperButtons, SwiperHeadingContainer, SwiperLeft, SwiperRight } from './Swiper.styles'
import 'swiper/css'
import 'swiper/css/navigation'
import { generateHtmlSafeId } from '@/helpers/UtilHelper'
import { useRouter } from 'next/router'

const FeaturedLinksSwiper: React.FC<{
  tiles: FeaturedSwiperTile[]
  sectionTitle: string
}> = ({ tiles, sectionTitle }) => {
  const swiperId = generateHtmlSafeId(sectionTitle)
  const router = useRouter()
  const lang = router.query.lang
  return (
    <Wrapper>
      <Spacing size="XXXL" />
      <Container spacing="XL">
        <SwiperHeadingContainer centeredArrows={false} data-testid="featured-swiper-header">
          <Heading size="L" color="brand-primary">
            {sectionTitle}
          </Heading>
          <SwiperButtons color="white">
            <SwiperLeft className={`${swiperId}-prev`}></SwiperLeft>
            <SwiperRight className={`${swiperId}-next`}></SwiperRight>
          </SwiperButtons>
        </SwiperHeadingContainer>
      </Container>
      <CustomLinksSwiperCont>
        <Swiper
          modules={[Navigation]}
          navigation={{ prevEl: `.${swiperId}-prev`, nextEl: `.${swiperId}-next` }}
          breakpoints={{
            0: {
              spaceBetween: 24,
              slidesPerView: 'auto',
              loop: false,
              lazyPreloadPrevNext: 1,
            },
            720: {
              spaceBetween: 40,
              slidesPerView: 'auto',
              loop: false,
              lazyPreloadPrevNext: 1,
            },
          }}
        >
          {tiles?.map(({ image, name, link }) => (
            <SwiperSlide className="featured-swiper-tile" key={link}>
              <Tile href={`/${lang}/adventures/${link}`}>
                <TileImage>
                  <Image src={image} alt={name} width={0} height={0} sizes="100%" loading="lazy" />
                </TileImage>
                <Spacing size="M" />
                <Spacing size="XXS" />
                <Heading size="S" as="h3" weight="heading" color="brand-primary">
                  {name}
                </Heading>
              </Tile>
            </SwiperSlide>
          ))}
        </Swiper>
      </CustomLinksSwiperCont>
      <Spacing size="XXXL" />
    </Wrapper>
  )
}

export default FeaturedLinksSwiper
