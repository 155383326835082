import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_LARGE, BREAKPOINT_SMALL } from '@/constant/ui'
import { SwiperHeadingContainer } from './Swiper.styles'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  ${SwiperHeadingContainer} {
    justify-content: flex-end;
  }
`
export const CustomLinksSwiperCont = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  .swiper {
    padding-left: calc((100% - 1296px) / 2);
    padding-right: calc((100% - 1296px) / 2);
  }
  .clink-swiper-tile {
    width: 300px;
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    .swiper {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100vw;
    overflow: hidden;
    left: 0;

    .clink-swiper-tile {
      width: 80vw;
    }
    .swiper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`
export const TitleContainer = styled.div`
  max-width: 1224px;
  margin: 0 auto;
  padding: 0 100px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 0 16px;
  }
`
export const TileImage = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    object-fit: cover;
    object-position: center;
    transition: 0.25s var(--cubicBezierImage);
    transform: scale(1);
  }
`
export const Tile = styled(Link)`
  color: var(--text-primary);
  text-decoration: none;
  display: block;

  &:hover {
    ${TileImage} img {
      transform: scale(1.1);
    }
  }
`
