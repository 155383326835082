import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Label from './Label'
import { Content } from '@/components/BlogHeader.styles'

type BlogHeaderProps = {
  title: string
  children?: React.ReactNode
  label?: string
  inverse?: boolean
}

const BlogHeader: React.FC<BlogHeaderProps> = ({ children, label, title, inverse = false }) => {
  const labelColor = inverse ? 'text-inverse' : 'text-primary'
  const headingColor = inverse ? 'brand-primary' : 'text-primary'

  return (
    <>
      <Content>
        {label && (
          <>
            <Label as="span" color={labelColor} dataTestId="blog-header-label">
              {label}
            </Label>
            <Spacing size="XL" />
          </>
        )}
        <Heading size="XL" as="h1" color={headingColor} dataTestId="blog-header-title">
          {title}
        </Heading>
        {children}
      </Content>
    </>
  )
}

export default BlogHeader
