import { Wrapper } from '@/components/widgets/BlogDetails.styles'
import { Alignment } from '@/types'

type BlogDetailsProps = {
  author?: string
  date?: string
  readtime?: string
  alignment?: Alignment
}

const BlogDetails: React.FC<BlogDetailsProps> = ({ author, date, readtime, alignment = 'left' }) => {
  const details = [author ? `By ${author}` : '', date || '', readtime ? `${readtime} min read` : '']
    .filter((item) => item.length)
    .join(' · ')

  return (
    <Wrapper data-testid="blog-details" align={alignment}>
      {details}
    </Wrapper>
  )
}

export default BlogDetails
