import styled from 'styled-components'
import { BREAKPOINT_MOBILE_MENU } from './Topbar.styles'

export const Wrapper = styled.div`
  display: none;
  background: var(--brand-secondary);
  padding: 10px 0;
  margin-top: -10px;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: block;
  }
`
export const SearchTrigger = styled.div`
  border: 1px solid var(--grey-10);
  border-radius: 4px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
`
