import { Wrapper } from '@/components/widgets/CtaButton.styles'
import Button from '@/components/Button'

type CtaButtonProps = {
  link: string
  text: string
}

const CtaButton: React.FC<CtaButtonProps> = ({ link, text }) => {
  return (
    <Wrapper>
      <Button alternate size="M" onClick={() => (window.location.href = `${link}`)}>
        {text}
      </Button>
    </Wrapper>
  )
}

export default CtaButton
