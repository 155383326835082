import BackgroundImage from '@/components/BackgroundImage'
import {
  ContentContainer,
  ContentWrapper,
  ContributorImage,
  DescriptionWrapper,
  GridImage,
  ImageGrid,
  ImageSlide,
  ImageSwiperWrapper,
  ImageWrapper,
} from '@/components/widgets/ContentContributorHeader.styles'
import { FlexibleHeading as Heading, Spacing, Body } from '@big-red-group/storefront-common-ui'
import Container from '@/components/Container'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

const ContentContributorHeader: React.FC<{
  backgroundImage: string
  name: string
  description: string
  contributorImage: string
  quote?: string
  images?: string[]
}> = ({ backgroundImage, contributorImage, name, description, quote, images }) => {
  return (
    <>
      <BackgroundImage imageUrl={backgroundImage}>
        <ImageWrapper>
          <ContributorImage imageUrl={contributorImage} />
        </ImageWrapper>
      </BackgroundImage>
      <ContentWrapper>
        <Container>
          <ContentContainer>
            <Spacing size="S" />
            <Heading size="L" color="text-inverse">
              {name}
            </Heading>
            <Spacing size="M" />
            <DescriptionWrapper>
              <Body color="text-inverse" as="p">
                {description}
              </Body>
              {quote && (
                <>
                  <Spacing size="XXL" />
                  <Heading size="L" color="brand-primary">
                    {quote}
                  </Heading>
                </>
              )}
            </DescriptionWrapper>
          </ContentContainer>
        </Container>
        <Spacing />
        <Container>
          <ImageGrid>{images?.map((image) => <GridImage key={image} imageUrl={image} />)}</ImageGrid>
          <ImageSwiperWrapper>
            <Swiper
              breakpoints={{
                0: {
                  spaceBetween: 16,
                  slidesPerView: 'auto',
                  loop: false,
                  lazyPreloadPrevNext: 2,
                },
                720: {
                  spaceBetween: 16,
                  slidesPerView: 'auto',
                  loop: false,
                  lazyPreloadPrevNext: 2,
                },
              }}
            >
              {images?.map((image) => (
                <SwiperSlide key={image} className="swiper-tile">
                  <BackgroundImage imageUrl={image}>
                    <ImageSlide />
                  </BackgroundImage>
                </SwiperSlide>
              ))}
            </Swiper>
          </ImageSwiperWrapper>
        </Container>
        <Spacing size="XXL" />
      </ContentWrapper>
    </>
  )
}

export default ContentContributorHeader
