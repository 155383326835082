import {
  DesktopButton,
  HeadingButtonWrapper,
  HeadingWrapper,
  MobileButton,
  ProfileImagesWrapper,
  Wrapper,
  Content,
  ProfilePrefixWrapper,
  PrefixImage,
  Tiles,
  ScrollWrapper,
} from '@/components/ContributorShowcase.styles'
import Label from '@/components/Label'
import { FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import { Spacing } from '@big-red-group/storefront-common-ui'
import Container from '@/components/Container'
import CrewProfileImage from '@/components/CrewProfileImage'
import { ContributorShowcaseItem } from '@/types'
import { useState } from 'react'
import ProductCollection from '@/components/ProductCollection'
import { toProduct } from '@/helpers/WidgetHelper'
import { useRouter } from 'next/router'

const ContributorShowcase: React.FC<{
  title: string
  contributors: ContributorShowcaseItem[]
  hidePrice?: boolean
}> = ({ title, contributors, hidePrice = false }) => {
  const router = useRouter()
  const { lang } = router.query
  const [selectedProfile, setSelectedProfile] = useState(0)

  const renderProfilePrefix = (heading: string, imageUrl: string, blogUrl?: string) => {
    return (
      <ProfilePrefixWrapper onClick={() => blogUrl && router.push(`${lang}/${blogUrl}`)}>
        <PrefixImage src={imageUrl} alt="Crew member" loading="lazy" />
        <Heading size="XS" as="h3">
          {heading}
        </Heading>
      </ProfilePrefixWrapper>
    )
  }

  if (!contributors?.length) {
    return <></>
  }
  return (
    <>
      <Wrapper>
        <Content>
          <Container>
            <HeadingWrapper>
              <Label as="span" color="brand-secondary">
                Stories from the crew
              </Label>
              <HeadingButtonWrapper>
                <Heading size="L" weight="heading">
                  {title}
                </Heading>
              </HeadingButtonWrapper>
            </HeadingWrapper>
          </Container>
          <Container fullWidthMobile>
            <ScrollWrapper>
              <ProfileImagesWrapper>
                {contributors.map(({ contributorImage, showcaseHeading }, index) => {
                  return (
                    <CrewProfileImage
                      key={showcaseHeading}
                      image={contributorImage}
                      urlSegment={''}
                      selected={index === selectedProfile}
                      onClickProfile={() => setSelectedProfile(index)}
                    />
                  )
                })}
              </ProfileImagesWrapper>
              <Spacing />
            </ScrollWrapper>
          </Container>
        </Content>
      </Wrapper>

      <Tiles>
        <ProductCollection
          products={toProduct(contributors[selectedProfile].products)}
          id={contributors[selectedProfile].showcaseHeading}
          prefixElement={renderProfilePrefix(
            contributors[selectedProfile].showcaseHeading,
            contributors[selectedProfile].showcaseImageUrl,
            contributors[selectedProfile].blogUrlSegment
          )}
          smallMobileTile={false}
          hidePrice={hidePrice}
        />
      </Tiles>
    </>
  )
}
export default ContributorShowcase
