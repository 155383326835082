import styled from 'styled-components'
import Image from 'next/image'
import { BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'

export const Wrapper = styled.div`
  position: relative;
`
export const ProfileArrow = styled.small`
  bottom: -5px;
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--brand-primary);
  transform: translate(-50%, 50%);
  z-index: ${Z_INDEX.midHigh};
`

export const ProfileImage = styled(Image)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s var(--cubicBezierImage);
  z-index: ${Z_INDEX.base};
`

export const ProfileImageWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  user-select: none;
  overflow: hidden;
  border-radius: 50%;
  width: 86px;
  height: 86px;
  flex-shrink: 0;
  border: 5px solid transparent;
  
  ${({ selected }) =>
    selected &&
    `
    border-color: var(--brand-primary);
  `}}

  &:hover {
    ${ProfileImage} {
      width: 115%;
      height: 115%;
      position: relative;
    }
  }
`
