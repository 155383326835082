import { ImageContainer } from '@/components/widgets/BlogImage.styles'
import Image from 'next/image'

type BlogImageProps = {
  image: string
}

const BlogImage: React.FC<BlogImageProps> = ({ image }) => {
  return (
    <ImageContainer>
      <Image src={image} alt="feature image" width={0} height={0} sizes="100%" />
    </ImageContainer>
  )
}

export default BlogImage
