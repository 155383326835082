import { Wrapper, ImageBlock, TextBlock } from '@/components/ImageTextBlock.styles'
import { ImageAlignment } from '@/types'
import Container from './Container'
import { BrandColor } from '@big-red-group/storefront-common-ui'

const ImageTextBlock: React.FC<{
  children: React.ReactNode
  image: string
  imageAlignment?: ImageAlignment
  background?: BrandColor
  inverse?: boolean
}> = ({ children, image, imageAlignment = 'left', background, inverse = false }) => {
  return (
    <>
      <Wrapper
        className="image-text-block"
        customBackground={background}
        imageAlignment={imageAlignment}
        inverse={inverse}
        data-testid="wrapper"
      >
        <Container fullWidthMobile dataTestId="image-text-block">
          <ImageBlock image={image} className="block-img" />
          <TextBlock className="block-text">{children}</TextBlock>
        </Container>
      </Wrapper>
    </>
  )
}

export default ImageTextBlock
