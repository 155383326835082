import { Wrapper, Image } from '@/components/widgets/ImageGridThree.styles'

type ImageGridThreeProps = {
  first: string
  second: string
  third: string
}

const ImageGridThree: React.FC<ImageGridThreeProps> = ({ first, second, third }) => {
  return (
    <Wrapper>
      <Image image={first} />
      <Image image={second} />
      <Image image={third} />
    </Wrapper>
  )
}

export default ImageGridThree
