import { Wrapper, ProfileImage, ProfileImageWrapper, ProfileArrow } from '@/components/CrewProfileImage.styles'

const CrewProfileImage: React.FC<{
  image: string
  urlSegment: string
  selected: boolean
  onClickProfile: () => void
}> = ({ image, urlSegment, selected, onClickProfile }) => {
  return (
    <Wrapper>
      <ProfileImageWrapper selected={selected}>
        <ProfileImage src={image} alt="influencer" width={96} height={96} onClick={onClickProfile} draggable="false" />
      </ProfileImageWrapper>
      {selected && <ProfileArrow />}
    </Wrapper>
  )
}

export default CrewProfileImage
