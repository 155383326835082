import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import { EditorialBlog } from '@/types'
import {
  EditorialContainer,
  WrapperDivider,
  SectionTitle,
  Tile,
  TileImage,
  TileHeadingContain,
  Wrapper,
} from './EditorialSwiper.styles'
import 'swiper/css'
import 'swiper/css/pagination'
import { Spacing, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Container from '@/components/Container'
import Icon from '@/components/Icons'
import { useRouter } from 'next/router'

const EditorialSwiper: React.FC<{
  blogs: EditorialBlog[]
}> = ({ blogs }) => {
  const router = useRouter()
  const lang = router.query.lang
  if (!blogs?.length) return null

  const slicedBlogs = blogs?.slice(0, 3)
  return (
    <Wrapper data-testid="editorial-blogs">
      <WrapperDivider />
      <Spacing size="XXXL" />
      <Container spacing="XL">
        <SectionTitle>
          <Heading size="L" color="text-inverse" alignment="center" weight="heading" as="h2">
            Your to-do list could look different this year...
          </Heading>
        </SectionTitle>
      </Container>
      <EditorialContainer>
        <Swiper
          modules={[Pagination]}
          // navigation={{ prevEl: '.editorial-prev', nextEl: '.editorial-next' }}
          pagination
          lazyPreloadPrevNext={1}
          breakpoints={{
            0: {
              spaceBetween: 16,
              slidesPerView: 1,
              enabled: true,
              lazyPreloadPrevNext: 1,
              centeredSlides: true,
            },
            500: {
              spaceBetween: 24,
              slidesPerView: 2,
              enabled: true,
              lazyPreloadPrevNext: 1,
              centeredSlides: true,
            },
            1020: {
              spaceBetween: 44,
              slidesPerView: 3,
              enabled: false,
              lazyPreloadPrevNext: 0,
            },
          }}
        >
          {blogs?.map(({ image, title, link }) => (
            <SwiperSlide className="clink-swiper-tile" key={link}>
              <Tile href={`/${lang}/${link}`}>
                <TileImage>
                  <Image src={image} alt={title} width={0} height={0} sizes="100%" loading="lazy" />
                </TileImage>
                <Spacing size="M" />
                <TileHeadingContain>
                  <Heading size="S" color="brand-secondary" weight="heading" as="h3">
                    {title}
                  </Heading>
                  <Icon name="arrowRight" width={38} />
                </TileHeadingContain>
              </Tile>
            </SwiperSlide>
          ))}
          <Spacing size="L" />
        </Swiper>
      </EditorialContainer>
      <Spacing size="L" />
    </Wrapper>
  )
}

export default EditorialSwiper
