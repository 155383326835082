import Link from 'next/link'
import styled from 'styled-components'
import { BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL, Z_INDEX } from '@/constant/ui'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: var(--brand-secondary);
  background-size: cover;
`
export const WrapperDivider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: white;
  z-index: ${Z_INDEX.base};

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    height: 20vh;
  }
`
export const EditorialContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: ${Z_INDEX.low};

  .swiper {
    padding-left: calc((100% - 1296px) / 2);
    padding-right: calc((100% - 1296px) / 2);
  }
  .swiper-pagination-bullet {
    opacity: 1;
    background: var(--brand-accent-secondary-dark);
    width: 16px;
    border-radius: 0;
    height: 3px;
  }
  .swiper-pagination-bullet-active {
    background: var(--brand-secondary);
  }

  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    .clink-swiper-tile:nth-child(2) {
      margin-top: 60px;
    }
  }

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    .swiper {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    width: 100vw;
    overflow: hidden;
    left: 0;

    .swiper {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`
export const SectionTitle = styled.div`
  text-align: center;
  text-wrap: balance;
  width: 65%;
  margin: 0 auto;
`
export const TileHeadingContain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  color: var(--brand-secondary);

  svg {
    flex-shrink: 0;
    position: relative;
    left: -10px;
    transition: 0.25s var(--cubicBezierImage);
  }
`
export const TileImage = styled.div`
  width: 100%;
  height: 418px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.45s var(--cubicBezierImage);
    transform: scale(1);
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    height: 320px;
  }
`
export const Tile = styled(Link)`
  color: var(--text-primary);
  text-decoration: none;
  display: block;
  padding: 24px;
  background: var(--grey-25);

  &:hover {
    ${TileImage} img {
      transform: scale(1.1);
    }
    ${TileHeadingContain} svg {
      left: 0;
    }
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    padding: 24px 16px;
  }
`
