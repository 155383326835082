import styled from 'styled-components'
import { BREAKPOINT_MEDIUM, BREAKPOINT_MEDIUM_LARGE } from '@/constant/ui'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 130%;
  gap: 15px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  margin: 60px 0;
  aspect-ratio: 1 / 0.5;

  @media (max-width: ${BREAKPOINT_MEDIUM_LARGE}px) {
    width: 110%;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 100%;
    gap: 10px;
    aspect-ratio: 1 / 0.9;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
  }
`

export const Image = styled.div<{ image: string }>`
  background-image: url('${({ image }) => image}');
  background-position: center;
  max-width: 100%;
  width: 100%;
  background-size: cover;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;

  &:last-child {
    grid-row-start: 1;
    grid-row-end: span 2;
    grid-column-start: 2;
    grid-column-end: span 1;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    &:last-child {
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  }
`
